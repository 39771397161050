import Slider from 'react-slick';
import styles from './Team.module.scss';
import './Slider.scss'

function Team(props: any) {

  var team = [
    {
      name: "ზურაბ პერტაია",
      text: "CEO",
      image1: "zpertaia.png"
    },
    {
      name: "ვასილ გრიგოლაია",
      text: "DevOps, DevSecOps, Solution Architect",
      image1: "vasili.png"
    },
    {
      name: "დიმიტრი კაციაშვილი",
      text: "Cyber and Information Security Consultant/Practitioner",
      image1: "dimitri.png"
    },
    {
      name: "ზურა არჩვაძე",
      text: "Database Administrator",
      image1: "zurab.png"
    },
    {
      name: "კახაბერ გოგვაძე",
      text: "Database Administrator",
      image1: "kakha.png"
    },
    {
      name: "გიორგი კარდენახიშვილი",
      text: "Dynatrace Engineer",
      image1: "giorgi.png",
    },
    // {
    //   name: "გირშელ ჭოხონელიძე",
    //   text: "Cyber and Information Security Consultant/Practitioner",
    //   image1: "nika1.png",
    //   image2: "nika2.png"
    // },
    // {
    //   name: "გიორგი კვინიკაძე",
    //   text: "PLACEHOLDER",
    //   image1: "natia1.png",
    //   image2: "natia2.png"
    // },
  ]
  
  var settings = {
    dots: true,
    infinite: true,
    initialSlide: 0,
    slidesToShow: 3,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 1000,
    rows: 1,
    customPaging: (i: number) => {
      return (
        <div className={styles.dot}>

        </div>)
    },
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "187.5px"
        },
      },
      {
        breakpoint: 1540,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "187.5px"
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "90px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 1075,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "212.5px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "112.5px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "3px",
          arrows: false,
          autoplay: true
        },
      },
      {
        breakpoint: 470,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5px",
          arrows: false,
          autoplay: true
        },
      },
    ],
  };

  return (
    <div className={styles.stars + " teamStars " + (props.isLight ? styles.Light : styles.Dark)}>

      <div className={styles.header}>ჩვენი <p className={styles.headerp}>გუნდი</p></div>

      <div className={styles.rectangles}>
        <div className={styles.rectanglesChild}>
          <Slider {...settings}>
            {team.map((item, index) => {
              if (index === team.length - 1) {
                return (
                  <div className={styles.team} key={index}>
                    <div className={styles.top + " " + styles['team3']}>
                      <img src={require('../assets/Images/' + item.image1)} className={styles.image1} alt="" />
                    </div>

                    <div className={styles.bottom + " " + styles['team3']}>
                      <div className={styles.name}>
                        {item.name}
                      </div>
                      <div className={styles.description}>
                        {item.text}
                      </div>
                    </div>
                  </div>
                );
              } else 
              return (
                <div className={styles.team} key={index}>
                  {/* 6 members, last item is the same color */}
                  {/* hard coded 6th item to team2 style */}
                  <div className={styles.top + " " + styles['team' + (index === 6 ? 2 : index % 3 + 1)]}>
                    <img src={require('../assets/Images/' + item.image1)} className={styles.image1} alt="" />
                  </div>

                  <div className={styles.bottom + " " + styles['team' + (index === 6 ? 2 : index % 3 + 1)]}>
                    <div className={styles.name}>
                      {item.name}
                    </div>
                    <div className={styles.description}>
                      {item.text}
                    </div>
                  </div>
                </div>)
            })}
          </Slider>

        </div>
      </div>
      <div className={styles.portfolio}>
        <p>10+ years</p> of <p>experience</p>
      </div>


    </div >
  );
}

export default Team;