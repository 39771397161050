import styles from './Contact.module.scss';
import { MapContainer, Marker, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import './Map.scss';
import L from 'leaflet';
import facebook from '../assets/Images/facebook.png';
import inst from '../assets/Images/inst.png';
import linkedin from '../assets/Images/linkedin.png';

const markerIcon = L.icon({
    iconUrl: require('../assets/Images/map-marker-icon.png'),
    iconSize: [35, 35]
});

function Contact(props: any) {
    return (
        <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
            <div className={styles.header}>
                დაგვიკავშირდით
            </div>
            <div className={styles.middle}>
                <div className={styles.follow}>
                    <p className={styles.followp}>follow us</p>
                    <div className={styles.social}>
                        <a href="https://www.facebook.com/TechseedGroup" rel="noreferrer" target="_blank">
                            <img width={40} src={facebook} alt="" />
                        </a>
                        <a href="https://www.linkedin.com/company/techgrowge/" rel="noreferrer" target="_blank">
                            <img width={40} src={linkedin} alt="" />
                        </a>
                        {/* <a href="https://www.instagram.com/techseedgroup/?igshid=NTdlMDg3MTY%3D&fbclid=IwAR0pqu79owUYWLBL5h29nMkvkOi2uoCz9No_tcitTnZ6M6krLy84WUeB42c&__coig_restricted=1" rel="noreferrer" target="_blank">
                            <img width={40} src={inst} alt="" />
                        </a> */}

                    </div>
                </div>

                <div className={styles.getInTouch}>
                    <p className={styles.getInTouchp}>get in touch</p>
                    <svg className={styles.arrow} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 0V3H14.885L0.5 17.385L2.615 19.5L17 5.115V18H20V0H2Z" />
                    </svg>
                    <div className={styles.line}></div>
                    <p className={styles.mail}>info@techgrow.ge</p>
                    <div className={styles.contact}>
                        <svg className={styles.blue} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.78 13.7117L13.0538 10.3237C12.8776 10.1636 12.6462 10.0782 12.4082 10.0856C12.1703 10.093 11.9446 10.1925 11.7787 10.3631L9.58509 12.6191C9.05709 12.5182 7.99559 12.1873 6.90293 11.0974C5.81026 10.0038 5.47934 8.93956 5.38126 8.41522L7.63534 6.22072C7.80622 6.05492 7.90588 5.82914 7.91324 5.59116C7.9206 5.35318 7.83507 5.12168 7.67476 4.94564L4.28768 1.22031C4.1273 1.04372 3.9044 0.936602 3.66632 0.921711C3.42823 0.90682 3.19372 0.985326 3.01259 1.14056L1.02343 2.84647C0.864944 3.00553 0.770351 3.21722 0.757592 3.44139C0.743842 3.67056 0.481675 9.09906 4.69101 13.3102C8.36317 16.9815 12.963 17.2501 14.2298 17.2501C14.415 17.2501 14.5287 17.2446 14.5589 17.2427C14.7831 17.2302 14.9946 17.1352 15.1529 16.976L16.8579 14.9859C17.0138 14.8053 17.0929 14.571 17.0783 14.3329C17.0637 14.0949 16.9567 13.8719 16.78 13.7117Z" />
                        </svg>
                        <p className={styles.blue}>tel:</p> +995 551 50 60 70
                    </div>
                    <div className={styles.contact}>
                        <svg className={styles.red} width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" d="M0.583984 17.3334H13.4173V19.1667H0.583984V17.3334ZM7.00065 5.41671C5.99232 5.41671 5.16732 6.24171 5.16732 7.25004C5.16732 8.25837 5.99232 9.08337 7.00065 9.08337C7.48688 9.08337 7.9532 8.89022 8.29701 8.5464C8.64083 8.20259 8.83398 7.73627 8.83398 7.25004C8.83398 6.76381 8.64083 6.29749 8.29701 5.95368C7.9532 5.60986 7.48688 5.41671 7.00065 5.41671ZM7.00065 0.833374C9.99815 0.833374 13.4173 3.08837 13.4173 7.38754C13.4173 10.2475 11.2815 13.2634 7.00065 16.4167C2.71982 13.2634 0.583984 10.2475 0.583984 7.38754C0.583984 3.08837 4.00315 0.833374 7.00065 0.833374Z" />
                        </svg>
                        <p className={styles.red}>address:</p> Business Center Atrium
                    </div>
                </div>
            </div>
            <div className={styles.map}>
                <MapContainer style={{ width: "100%", height: "500px" }} center={[41.72542772766884, 44.78051884014244]} zoom={17} scrollWheelZoom={false}>
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[41.72542772766884, 44.78051884014244]} icon={markerIcon}>
                    </Marker>
                </MapContainer>
            </div>
        </div>)
}

export default Contact;