import Slider from 'react-slick';
import styles from './Stars.module.scss';
import './Slider.scss'

function Stars(props: any) {

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    autoplay: true,
    speed: 1000,
    rows: 1,
    customPaging: (i: number) => {
      return (
        <div className={styles.dot}>

        </div>)
    },
    responsive: [
      {
        breakpoint: 1610,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          rows: 1,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "90px"
        },
      },
      {
        breakpoint: 1075,
        settings: {
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "90px"
        },
      },
      {
        breakpoint: 660,
        settings: {
          arrows: false,
          touchMove: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "90px",
        },
      },
      {
        breakpoint: 570,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "5px",
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className={styles.stars + " servicesStars " + (props.isLight ? styles.Light : styles.Dark)}>

      <p className={styles.header}><div><p className={styles.tech}>TECH</p>GROW</div> კურსები და ტრენინგები</p>

      <div className={styles.services}>
        <div className={styles.serviceTitles}>
          <p className={styles.sec}>cybersecurity</p>
          <p className={styles.devops}>devops</p>
          <p className={styles.cons}>database</p>
          <p className={styles.devsecops}>devsecops</p>
        </div>
      </div>

      <div className={styles.rectangles}>
        <div className={styles.rectanglesChild}>
          <Slider {...settings}>
            <a href='/work'>
              <div className={styles.secRectangle}>

                <div className={styles.top}>
                  <div className={styles.initial}>
                    <p>c</p>
                  </div>
                  <div className={styles.left}>
                    <p className={styles.serviceLabel}>
                      კიბერ
                      <br />უსაფრთხოება
                    </p>
                  </div>
                </div>
                {/* <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                <p>DevOps-ის უახლესი კურსი შექმნილია იმისათვის, რომ ამოავსოს პროგრამულ უზრუნველყოფასა და IT ოპერაციებს შორის არსებული სიცარიელე. ჩვენი ექსპერტი ინსტრუქტორები სტუდენტებს უწყვეტი ინტეგრაციის, უწყვეტი მიწოდებისა და ავტომატური განაწილების სირთულეებში გაუძღვებიან.</p>
                </div>
              </div> */}
              </div>
            </a>

            <a href='/work'>
              <div className={styles.devopsRectangle}>
                <div className={styles.top}>
                  <div className={styles.initial}>
                    <p>d</p>
                  </div>
                  <div className={styles.left}>
                    <p className={styles.serviceLabel}>
                      დევოპსი (DevOps)
                    </p>
                  </div>
                </div>
                {/* <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                <p>DevOps-ის უახლესი კურსი შექმნილია იმისათვის, რომ ამოავსოს პროგრამულ უზრუნველყოფასა და IT ოპერაციებს შორის არსებული სიცარიელე. ჩვენი ექსპერტი ინსტრუქტორები სტუდენტებს უწყვეტი ინტეგრაციის, უწყვეტი მიწოდებისა და ავტომატური განაწილების სირთულეებში გაუძღვებიან.</p>
                </div>
              </div> */}
              </div>
            </a>

            <a href='/work'>
              <div className={styles.consRectangle}>
                <div className={styles.top}><div className={styles.initial}>
                  <p>D</p>
                </div>
                  <div className={styles.left}>
                    <p className={styles.serviceLabel}>
                      მონაცემთა ბაზები
                    </p>
                  </div>
                </div>
                {/* <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>We can help you build, maintain, and monitor your network with our comprehensive suite of services and products. Our team of experts will ensure that your  IT infrastructure is secure, reliable, and up-to-date with the latest technology.</p>
                  </div>
                </div> */}
              </div>
            </a>

            <a href='/work'>
            <div className={styles.devsecopsRectangle}>
              <div className={styles.top}>
                <div className={styles.initial}>
                  <p>d</p>
                </div>
                <div className={styles.left}>
                  <p className={styles.serviceLabel}>
                    დევსეკოფსი (DevSecOps)
                  </p>
                </div>
              </div>
              {/* <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>Our DevSecOps solutions align with your organizational needs, ensuring modern, secure workflows. We excel in DevSecOps practices, addressing security, integration, and monitoring. Our proactive methods enhance collaboration, transparency, and performance for your business's operational framework.</p>
                </div>
              </div> */}
            </div>
            </a>

            {/* <div className={styles.dbadminRectangle}>
              <div className={styles.top}>
              <div className={styles.initial}>
              <p>d</p>
                </div>
                <div className={styles.left}>
                  <p className={styles.serviceLabel}>
                    Database
                  </p>
                </div>
              </div>
              <div className={styles.bottom}>
                <div className={styles.bottomDescription}>
                  <p>With a wealth of experience, our database administrators are devoted to ensuring that your data remains secure, accessible, and performing at its pinnacle. We stay abreast of the latest advancements in database technology, ensuring your systems are modern and capable of meeting the evolving demands of your business.</p>
                </div>
              </div>
            </div> */}
          </Slider>

        </div>

      </div>


    </div >
  );
}

export default Stars;