import styles from './Header.module.scss';
import logo from '../assets/Images/Logo.svg'
import 'bootstrap/dist/css/bootstrap.min.css'
import { MdMenu } from 'react-icons/md'

import {
    CCollapse,
    CContainer,
    CNavbar,
    CNavbarNav,
    CNavItem,
    CNavLink
} from '@coreui/react';
import { useState } from 'react';


function Header(props: any) {

    const [visible, setVisible] = useState(false)
    return (
        <div className={styles.Header + " " + (!props.isLight ? styles.dark : "")}>
            <div className={styles.logo}>
                <a href="/">
                    <img className={styles.logoimg} src={logo} height={44} alt="" />
                </a>
            </div>

            <div className={styles.links2}>
                <CNavbar expand="lg" colorScheme="light" className={`${styles.navBar}`}>
                    <CContainer fluid>
                        <MdMenu className={styles.dropButton + " " + (visible ? styles.active : "")} onClick={() => setVisible(!visible)}></MdMenu>
                        <CCollapse className={`navbar-collapse ${styles.links}`} visible={visible}>
                            <CNavbarNav className={styles.space_between}>
                                <CNavItem><CNavLink className={styles.navItem} href="about-us" active><span className={styles.space}>ჩვენ</span>შესახებ</CNavLink>
                                </CNavItem>
                                {/* <CNavItem>
                                    <CNavLink className={styles.navItem} href="services" active>Services</CNavLink>
                                </CNavItem> */}
                                <CNavItem>
                                    <CNavLink className={styles.navItem} href="team" active>გუნდი</CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink className={styles.navItem} href="work" active>ტრენინგები</CNavLink>
                                </CNavItem>
                                {/* <CNavItem>
                                    <CNavLink className={styles.navItem} href="talk-to-us" active><span className={styles.space}>Talk</span><span className={styles.space}>to</span>us</CNavLink>
                                </CNavItem> */}
                                <CNavItem>
                                    <CNavLink className={styles.navItem} href="contact" active>კონტაქტი</CNavLink>
                                </CNavItem>
                            </CNavbarNav>
                        </CCollapse>
                    </CContainer>
                </CNavbar>
            </div>
        </div>
    );
}

export default Header;