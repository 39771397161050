import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React from 'react';
import styles from './About.module.scss';
import src from '../assets/Images/residence.png';
import arrow from '../assets/Images/arrow.svg';
import Team from '../team/Team';
import circle1 from '../assets/Images/circle1.png';
import wregra1 from '../assets/Images/wregra1.png';

function AboutUs(props: any) {
    const [value, setValue] = React.useState('1');
    const [show, setShow] = React.useState(false);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    return (
        <div className={styles.main + " " + (props.isLight ? styles.Light : styles.Dark)}>
            <div className={styles.header}>
                ჩვენ შესახებ
            </div>

            <div className={styles.section2}>
                <div className={styles.img}>
                    <img src={wregra1} className={styles.image2} alt="" />
                    <img src={circle1} className={styles.image1} alt="" />
                </div>

                <div className={styles.text}>
                    <p className={styles.red}>TECHGROW</p> საგანმანათლებლო და საკონსულტაციო პლატფორმაა, რომელიც ტექნოლოგიური მიმართულებით, <p className={styles.purple}>კვალიფიკაციის</p> ასამაღლებელ <p className={styles.blue}>კურსებს</p>, უნარების გასაუმჯობესებელ <p className={styles.blue}>ტრენინგებსა</p> და საკონსულტაციო სერვისს გთავაზობთ.
                </div>

            </div>

            {/* <div className={styles.image}>
                <img src={src} alt="" />
            </div> */}

            <div className={styles.wwa}>
                <div className={styles.wwatext}>
                    <div>
                        ჩვენ <p>შესახებ</p>
                    </div>
                </div>

                <a href="/team">
                    <div className={styles.seeMore}>
                        <p>
                            see more <img src={arrow} alt="" />
                        </p>
                    </div>
                </a>
            </div>

            <div className={styles.aboutUs}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="about us" value="1" />
              </TabList>
            </Box> */}
                        <TabPanel value="1"><p className={styles.content}><span className={styles.red}>TECHGROW</span>-ში გვჯერა, რომ ისეთ უწყვეტად განვითარებად და ცვალებად სივრცეში <span className={styles.blue}>წარმატებული</span> ოპერირებისთვის, როგორიც ციფრული სამყარო და ზოგადად, ტექნოლოგიებია, ცოდნის მუდმივი განახლება და უახლესი მიდგომების დანერგვაა საჭირო. {show ? "  სწორედ ამიტომ, დარგის პროფესიონალებთან ერთად, რომელთაც როგორც საქართველოს ბაზარზე, ასევე საზღვარგარეთ მიღებული განათლება და მრავალწლიანი სამუშაო გამოცდილება აქვთ, ვქმნით სივრცეს, რომელიც კომპანიებსა და სფეროთი დაინტერესებულ ადამიანებს საშუალებას მისცემს ხარისხიანი განათლება მიიღონ და ჩვენგან წაღებული ცოდნით ბიზნესის ზრდას და განვითარებას დაეხმარონ." : ""}
                        </p><button className={styles.show} onClick={() => { setShow(!show) }}>{show ? "show less" : "show more"} <svg className={show ? styles.reverseArrow : ""} width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 6.625H12.5M6.875 1L12.5 6.625L6.875 12.25" stroke="#62E7F0" />
                        </svg>
                            </button></TabPanel>
                    </TabContext>
                </Box>
            </div>

            <div className={styles.team}>
                <Team isLight={props.isLight} />
            </div>
        </div >
    )

}

export default AboutUs;