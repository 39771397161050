import React, { useState } from 'react';
import styles from './App.module.scss';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Header from './header/Header';
import Paper from './paper/Paper';
import { ThemeSwitch } from './switch/ThemeSwitch';
import Main from './main/Main';
import Footer from './footer/Footer';
import Services from './services/Services';
import AboutUs from './about-us/About';
import TeamPage from './team-page/TeamPage';
import Contact from './contact-us/Contact';
import TalkToUs from './calendar/Calendar';
import Work from './our-work/Work';

function App() {

  const [isLight, changeTheme] = useState(window.innerWidth < 1075);

  const switchColor = () => {
    changeTheme(!isLight);
  }

  return (
    <div className={styles.App + " " + (isLight ? "" : styles.dark)}>

      <BrowserRouter>

        <Header isLight={isLight} />
        <ThemeSwitch handleClick={switchColor} isLight={isLight} />

        <div className={styles.router}>
          <Routes>
            <Route path='/' element={
              <Paper isLight={isLight} element={<Main isLight={isLight}></Main>}></Paper>
            }>
            </Route>
            {/* <Route path='services' element={
              <Paper isLight={isLight} element={<Services isLight={isLight}></Services>}></Paper>
            }>
            </Route> */}
            <Route path='about-us' element={
              <Paper isLight={isLight} element={<AboutUs isLight={isLight}></AboutUs>}></Paper>
            }>
            </Route>
            <Route path='team' element={
              <Paper isLight={isLight} element={<TeamPage isLight={isLight}></TeamPage>}></Paper>
            }>
            </Route>
            <Route path='contact' element={
              <Paper isLight={isLight} element={<Contact isLight={isLight}></Contact>}></Paper>
            }>
            </Route>
            {/* <Route path='talk-to-us' element={
              <Paper isLight={isLight} element={<TalkToUs isLight={isLight}></TalkToUs>}></Paper>
            }>
            </Route> */}
            <Route path='work' element={
              <Paper isLight={isLight} element={<Work isLight={isLight}></Work>}></Paper>
            }>
            </Route>
            <Route path='*' element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Footer isLight={isLight}></Footer>

      </BrowserRouter>

    </div>
  );
}

export default App;
