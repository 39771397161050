import styles from './TeamPage.module.scss';

function TeamPage(props: any) {

    var team = [
        {
            name: "ზურაბ პერტაია",
            text: "CEO",
            image1: "zpertaia.png"
        },
        {
            name: "ვასილი გრიგოლაია",
            text: "DevOps, DevSecOps და Solution Architect",
            image1: "vasili.png"
        },
        {
            name: "დიმიტრი კაციაშვილი ",
            text: "Cyber and Information Security Consultant/Practitioner ",
            image1: "dimitri.png"
        },
        {
            name: "ზურაბ არჩვაძე",
            text: "Database Administrator",
            image1: "zurab.png"
        },
        {
            name: "კახაბერ გოგვაძე",
            text: "Database Administrator ",
            image1: "kakha.png"
        },
        {
            name: "გიორგი კარდენახიშვილი",
            text: "Dynatrace Engineer",
            image1: "giorgi.png",
        },
        // {
        //     name: "გირშელ ჭოხონელიძე",
        //     text: "Cyber and Information Security Consultant/Practitioner ",
        //     image1: "nika1.png",
        //     image2: "nika2.png"
        // },
        // {
        //     name: "გიორგი კვინიკაძე",
        //     text: "PLACEHOLDER",
        //     image1: "natia1.png",
        //     image2: "natia2.png"
        // },
    ]

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <div className={styles.title}><p>our</p><p> team</p></div>
            </div>

            <div className={styles.grid}>
                {team.map((item, index) => {
                    // if (index === team.length - 1) {
                        // return null;
                    // }
                    return (
                        <div className={styles.team + " " + styles['team' + (index + 1)]} key={index}>
                            <div className={styles.top}>
                                <img src={require('../assets/Images/' + item.image1)} className={styles.image1} alt="" />
                                {/* <img src={require('../assets/Images/' + item.image2)} className={styles.image2} alt="" /> */}
                            </div>
                            <div className={styles.bottom}>
                                <p className={styles.name}>{item.name}</p>
                                <p className={styles.description}>{item.text}</p>
                            </div>
                        </div>
                    )
                })}
            </div>
            {/* <div className={styles.team + " " + styles.last}>
                <div className={styles.top}>
                    <img src={require('../assets/Images/' + team[team.length - 1].image1)} className={styles.image1} alt="" />
                </div>
                <div className={styles.bottom}>
                    <p className={styles.name}>{team[team.length - 1].name}</p>

                    <p className={styles.description}>{team[team.length - 1].text}</p>
                </div>
            </div> */}
        </div>)
}

export default TeamPage;